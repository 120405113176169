<template>
  <div>
    <div class="lg:hidden">
      <div class="py-2 flex flex-col font-semibold">
        <header class="px-6 py-2 text-lg flex text-text-alternate-1 pr-12">
          <button class="focus:outline-none" @click="$router.go(-1)">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto">Edit Profile</h2>
        </header>
        <BPageLoader v-if="isLoading" class="flex-grow" />
        <template v-else>
          <section
            class="mx-6 flex flex-col justify-between items-center pt-8 pb-2"
          >
            <div
              class="
                h-20
                w-20
                flex
                items-center
                justify-center
                rounded-full
                font-bold
                relative
              "
              :style="{
                backgroundColor: randomColor({
                  luminosity: 'bright',
                  seed: `${data.firstname[0]}${data.lastname[0]}`,
                }),
                color: '#fff',
              }"
            >
              <img
                :src="data.profilePhoto"
                v-if="data.profilePhoto"
                class="rounded-full"
              />
              <span v-else>{{ data.firstname[0] }}{{ data.lastname[0] }}</span>
              <button
                class="focus:outline-none absolute -right-1 bottom-1"
                @click="stage = 1"
              >
                <EditIcon class="h-7" />
              </button>
            </div>
          </section>
          <section class="mx-6">
            <div
              class="
                flex flex-col
                mt-4
                mb-3
                text-text-primary
                font-semibold
                text-sm
              "
            >
              <label>Bio</label>
              <textarea
                class="
                  border
                  rounded
                  border-border-primary
                  my-2
                  p-4
                  text-xs
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                v-model.trim="data.bio"
              />
            </div>
          </section>
          <section class="mx-6 mt-2">
            <form
              class="flex flex-col text-text-primary font-semibold text-sm"
              @submit.prevent="handleSubmit"
            >
              <label>
                Add Social Media Links
                <span class="font-medium">(maximum of 4)</span>
              </label>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                  mt-2
                "
              >
                <FBIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  :disabled="disableSocial && !Facebook.link"
                  placeholder="https://"
                  v-model.trim="Facebook.link"
                />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                "
              >
                <IGIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  :disabled="disableSocial && !Instagram.link"
                  placeholder="https://"
                  v-model.trim="Instagram.link"
                />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                "
              >
                <TWIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  :disabled="disableSocial && !Twitter.link"
                  placeholder="https://"
                  v-model.trim="Twitter.link"
                />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                "
              >
                <YTIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  :disabled="disableSocial && !Youtube.link"
                  placeholder="https://"
                  v-model.trim="Youtube.link"
                />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                "
              >
                <TKKIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  :disabled="disableSocial && !Tiktok.link"
                  placeholder="https://"
                  v-model.trim="Tiktok.link"
                />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                "
              >
                <TLGIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  :disabled="disableSocial && !Telegram.link"
                  placeholder="https://"
                  v-model.trim="Telegram.link"
                />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                "
              >
                <WEBIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  :disabled="disableSocial && !Web.link"
                  placeholder="https://"
                  v-model.trim="Web.link"
                />
              </div>
              <button
                class="
                  font-bold
                  mx-auto
                  w-full
                  my-10
                  py-4
                  xs:py-5
                  text-sm text-center text-btn-text-primary
                  bg-btn-bg-primary
                  inline-block
                  rounded
                "
                type="submit"
                :disabled="updateResponse.isLoading"
              >
                <BButtonLoader
                  class="h-4 w-4 mx-auto"
                  v-if="updateResponse.isLoading"
                />
                <span v-else>Update Profile</span>
              </button>
            </form>
          </section>
        </template>
        <BModal :isOpen="stage" @close="stage = null">
          <ul class="flex flex-col text-text-primary pb-4" v-if="stage === 1">
            <li class="my-3">
              <label class="cursor-pointer font-semibold text-sm">
                Choose from gallery
                <input
                  type="file"
                  hidden
                  accept="image/png,image/jpeg,image/jpg"
                  @change="handleFileInput"
                />
              </label>
            </li>
            <li class="my-3">
              <button
                class="focus:outline-none font-semibold text-sm"
                @click="stage = 2"
              >
                Choose an avatar
              </button>
            </li>
          </ul>
          <div v-else-if="stage === 2">
            <div class="grid grid-cols-4 gap-5 mt-4">
              <label
                class="mx-auto"
                v-for="(avatar, index) in avatars"
                :key="index"
              >
                <span class="relative inline-block">
                  <img class="h-16" :src="avatar" />
                  <TickIcon
                    v-if="selectedAvatar === avatar"
                    class="absolute right-0 top-0 h-5 fill-current"
                    style="fill: #577387"
                  />
                </span>
                <input
                  type="radio"
                  v-model="selectedAvatar"
                  :value="avatar"
                  hidden
                />
              </label>
            </div>
            <button
              class="
                font-bold
                mx-auto
                w-full
                mt-10
                mb-6
                py-4
                xs:py-5
                text-sm text-center text-btn-text-primary
                bg-btn-bg-primary
                inline-block
                rounded
              "
              @click="applyAvatar"
            >
              Apply
            </button>
          </div>
          <div v-else-if="stage === 3">
            <BPageLoader v-if="isCompressing" />
            <template v-else>
              <Cropper
                class="cropper"
                ref="cropper"
                :src="userImage.content"
                :stencil-props="{
                  aspectRatio: 1,
                  previewClass: 'rounded-full',
                }"
              />
              <button
                class="
                  font-bold
                  mx-auto
                  w-full
                  mt-10
                  mb-6
                  py-4
                  xs:py-5
                  text-sm text-center text-btn-text-primary
                  bg-btn-bg-primary
                  inline-block
                  rounded
                "
                @click="applyImage"
              >
                Apply
              </button>
            </template>
          </div>
        </BModal>
      </div>
    </div>
    <div class="hidden lg:flex justify-center mt-24 pt-4">
      <div class="b-card px-26 pt-8">
        <div class="py-2 flex flex-col font-semibold">
          <header class="px-6 py-2 text-lg flex text-text-alternate-1 pr-12">
            <button class="focus:outline-none" @click="$router.go(-1)">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto">Edit Profile</h2>
          </header>
          <BPageLoader v-if="isLoading" class="flex-grow" />
          <template v-else>
            <section
              class="mx-6 flex flex-col justify-between items-center pt-8 pb-2"
            >
              <div
                class="
                  h-20
                  w-20
                  flex
                  items-center
                  justify-center
                  rounded-full
                  font-bold
                  relative
                "
                :style="{
                  backgroundColor: randomColor({
                    luminosity: 'bright',
                    seed: `${data.firstname[0]}${data.lastname[0]}`,
                  }),
                  color: '#fff',
                }"
              >
                <img
                  :src="data.profilePhoto"
                  v-if="data.profilePhoto"
                  class="rounded-full"
                />
                <span v-else
                  >{{ data.firstname[0] }}{{ data.lastname[0] }}</span
                >
                <button
                  class="focus:outline-none absolute -right-1 bottom-1"
                  @click="stage = 1"
                >
                  <EditIcon class="h-7" />
                </button>
              </div>
            </section>
            <section class="mx-6">
              <div
                class="
                  flex flex-col
                  mt-4
                  mb-3
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <label>Bio</label>
                <textarea
                  class="
                    border
                    rounded
                    border-border-primary
                    my-2
                    p-4
                    text-xs
                    font-medium
                    focus:outline-none
                    input-shadow
                  "
                  v-model.trim="data.bio"
                />
              </div>
            </section>
            <section class="mx-6 mt-2">
              <form
                class="flex flex-col text-text-primary font-semibold text-sm"
                @submit.prevent="handleSubmit"
              >
                <label>
                  Add Social Media Links
                  <span class="font-medium">(maximum of 4)</span>
                </label>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                    mt-2
                  "
                >
                  <FBIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    :disabled="disableSocial && !Facebook.link"
                    placeholder="https://"
                    v-model.trim="Facebook.link"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <IGIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    :disabled="disableSocial && !Instagram.link"
                    placeholder="https://"
                    v-model.trim="Instagram.link"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <TWIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    :disabled="disableSocial && !Twitter.link"
                    placeholder="https://"
                    v-model.trim="Twitter.link"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <YTIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    :disabled="disableSocial && !Youtube.link"
                    placeholder="https://"
                    v-model.trim="Youtube.link"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <TKKIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    :disabled="disableSocial && !Tiktok.link"
                    placeholder="https://"
                    v-model.trim="Tiktok.link"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <TLGIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    :disabled="disableSocial && !Telegram.link"
                    placeholder="https://"
                    v-model.trim="Telegram.link"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <WEBIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    :disabled="disableSocial && !Web.link"
                    placeholder="https://"
                    v-model.trim="Web.link"
                  />
                </div>
                <button
                  class="
                    font-bold
                    mx-auto
                    w-full
                    my-10
                    py-4
                    xs:py-5
                    text-sm text-center text-btn-text-primary
                    bg-btn-bg-primary
                    inline-block
                    rounded
                  "
                  type="submit"
                  :disabled="updateResponse.isLoading"
                >
                  <BButtonLoader
                    class="h-4 w-4 mx-auto"
                    v-if="updateResponse.isLoading"
                  />
                  <span v-else>Update Profile</span>
                </button>
              </form>
            </section>
          </template>
          <BModal :isOpen="stage" @close="stage = null">
            <ul class="flex flex-col text-text-primary pb-4" v-if="stage === 1">
              <li class="my-3">
                <label class="cursor-pointer font-semibold text-sm">
                  Choose from gallery
                  <input
                    type="file"
                    hidden
                    accept="image/png,image/jpeg,image/jpg"
                    @change="handleFileInput"
                  />
                </label>
              </li>
              <li class="my-3">
                <button
                  class="focus:outline-none font-semibold text-sm"
                  @click="stage = 2"
                >
                  Choose an avatar
                </button>
              </li>
            </ul>
            <div v-else-if="stage === 2">
              <div class="grid grid-cols-4 gap-5 mt-4">
                <label
                  class="mx-auto"
                  v-for="(avatar, index) in avatars"
                  :key="index"
                >
                  <span class="relative inline-block">
                    <img class="h-16" :src="avatar" />
                    <TickIcon
                      v-if="selectedAvatar === avatar"
                      class="absolute right-0 top-0 h-5 fill-current"
                      style="fill: #577387"
                    />
                  </span>
                  <input
                    type="radio"
                    v-model="selectedAvatar"
                    :value="avatar"
                    hidden
                  />
                </label>
              </div>
              <button
                class="
                  font-bold
                  mx-auto
                  w-full
                  mt-10
                  mb-6
                  py-4
                  xs:py-5
                  text-sm text-center text-btn-text-primary
                  bg-btn-bg-primary
                  inline-block
                  rounded
                "
                @click="applyAvatar"
              >
                Apply
              </button>
            </div>
            <div v-else-if="stage === 3">
              <BPageLoader v-if="isCompressing" />
              <template v-else>
                <Cropper
                  class="cropper"
                  ref="cropper"
                  :src="userImage.content"
                  :stencil-props="{
                    aspectRatio: 1,
                    previewClass: 'rounded-full',
                  }"
                />
                <button
                  class="
                    font-bold
                    mx-auto
                    w-full
                    mt-10
                    mb-6
                    py-4
                    xs:py-5
                    text-sm text-center text-btn-text-primary
                    bg-btn-bg-primary
                    inline-block
                    rounded
                  "
                  @click="applyImage"
                >
                  Apply
                </button>
              </template>
            </div>
          </BModal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, computed, reactive, watch } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { getUserProfileByUsername, updateUserProfile } from '@/services/api';
import { useRoute } from 'vue-router';
import randomColor from 'randomcolor';
import { useToast } from 'vue-toastification';
import { Cropper } from 'vue-advanced-cropper';
import BModal from '@/components/ui/BModal';
import 'vue-advanced-cropper/dist/style.css';
import imageCompression from 'browser-image-compression';
import { getFileDetails, getDataUrlFromImage } from '@/utils';
const images = { avatar: 1, gallery: 2 };

const socials = {
  Facebook: { link: '' },
  Instagram: { link: '' },
  Twitter: { link: '' },
  Youtube: { link: '' },
  Telegram: { link: '' },
  Tiktok: { link: '' },
  Web: { link: '' },
};

const avatars = [
  require('@/assets/images/avatar/avatar-7.png'),
  require('@/assets/images/avatar/avatar-6.png'),
  require('@/assets/images/avatar/avatar-5.png'),
  require('@/assets/images/avatar/avatar-4.png'),
  require('@/assets/images/avatar/avatar-3.png'),
  require('@/assets/images/avatar/avatar-2.png'),
  require('@/assets/images/avatar/avatar-1.png'),
  require('@/assets/images/avatar/avatar-0.png'),
];

export default {
  name: 'EditProfile',

  components: { BModal, Cropper },

  setup() {
    const route = useRoute();
    const [response, fetchUserProfile] = useApi(getUserProfileByUsername);
    const toast = useToast();
    const [updateResponse, editUserProfile] = useApi(updateUserProfile);
    fetchUserProfile(route.params.username);
    let imageChoosen = null;

    const bio = ref('');
    const selectedAvatar = ref('');
    const cropper = ref(null);
    const userImage = ref('');
    const stage = ref(null);
    const isCompressing = ref(false);
    const socialmedialinks = reactive({ ...socials });

    watch(response, ({ data }) => {
      data?.sociallinks.forEach(
        (social) => (socialmedialinks[social.smname] = social)
      );
    });

    watch(updateResponse, ({ isSuccess }) => {
      if (isSuccess) {
        toast.success('Updated successfully');
      }
    });

    const disableSocial = computed(() => {
      return (
        Object.values(socialmedialinks).reduce(
          (acc, next) => acc + (next.link.length > 0),
          0
        ) === 4
      );
    });

    const handleFileInput = async ({ target }) => {
      try {
        let file = target.files[0];
        stage.value = 3;
        isCompressing.value = true;
        file = await imageCompression(file, { maxSizeMB: 0.05 });
        userImage.value = await getFileDetails(file);
      } catch (error) {
        console.log(error);
      } finally {
        isCompressing.value = false;
      }
    };

    const handleSubmit = () => {
      let profilePhoto = undefined;
      if (imageChoosen === images.avatar) {
        profilePhoto = {
          base64: response.data.profilePhoto,
          name: selectedAvatar.value.split('/').pop(),
          mimetype: 'image/png',
        };
      } else if (imageChoosen === images.gallery) {
        profilePhoto = {
          base64: response.data.profilePhoto,
          name: userImage.value.name,
          mimetype: 'image/jpeg',
        };
      }
      const payload = {
        bio: response.data.bio,
        sociallinks: Object.values(socialmedialinks),
        profilePhoto,
      };
      editUserProfile({ id: response.data.userid, payload });
    };

    const applyAvatar = async () => {
      const img = new Image();
      img.src = selectedAvatar.value;
      const base64 = await getDataUrlFromImage(img);
      const imageSize = Math.round(
        Buffer.from(base64.substring(base64.indexOf(',') + 1)).length / 1e3
      );
      if (imageSize > 1000) {
        toast.error('Image size is too large, Maximum image size should be 1MB.');
      } else {
        response.data.profilePhoto = base64;
        imageChoosen = images.avatar;
        stage.value = null;
      }
    };

    const applyImage = () => {
      const { canvas } = cropper.value.getResult();
      response.data.profilePhoto = canvas.toDataURL('image/jpeg', 0.5);
      imageChoosen = images.gallery;
      stage.value = null;
    };

    return {
      bio,
      stage,
      cropper,
      applyImage,
      disableSocial,
      handleFileInput,
      userImage,
      handleSubmit,
      applyAvatar,
      selectedAvatar,
      ...toRefs(response),
      ...toRefs(socialmedialinks),
      randomColor,
      updateResponse,
      avatars,
      isCompressing,
    };
  },
};
</script>

<style lang="scss" scoped>
.nav-border {
  border: 4px solid transparent;
}

.nav-active {
  border-bottom-color: #577387;
}

.cropper {
  max-height: 40vh;
  background: #ddd;
}
</style>
